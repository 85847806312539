"use strict";

var formLocalStorageKey = "_e_indiain_";
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;
    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
function getTranslationsFromLang() {
  var currLang = document.querySelector('body').getAttribute('data-current-lang');
  var ajaxParams = [];
  for (var i = 0; i < arguments.length; i++) {
    ajaxParams.push([arguments[i]]);
  }
  var formData = 'params=[' + ajaxParams.map(function (key) {
    return "[\"".concat(key, "\", \"").concat(currLang, "\"]");
  }).join(',').replace(/%20/g, '+') + ']';
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '//' + window.location.hostname + '/langTranslation', false);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send(formData);
  if (xhr.status === 200) {
    return JSON.parse(xhr.responseText);
  } else {
    var _xhr = new XMLHttpRequest();
    _xhr.open('POST', '//' + window.location.href + "main.js:getTranslationsFromLang()\n\n Languages access has failed");
    _xhr.send(null);
  }
}
var translations = getTranslationsFromLang('form field warning', 'form date callendar button prev', 'form date callendar button next', 'form label terms single 10', 'form label terms single 30', 'form label terms multiple 30', 'type of visa under text', 'type of visa under text single 30', 'type of visa under text single 30', 'type of visa under text multiple 30', 'Menu 3 Link 1', 'form label nonlatin error', 'type of visa under text usa', 'invalid mimeType');

//////////////////////////////
// show phone number on page
//////////////////////////////

function send_to_logger(data) {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '/logger.php', true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send("message=".concat(data));
}
function getPhoneNumbers() {
  var formData = new FormData();
  formData.append('language', document.body.dataset.currentLang);
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '//' + window.location.hostname + '/get-phone-number', true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send("language=".concat(document.body.dataset.currentLang));
  xhr.onreadystatechange = function (e) {
    if (this.readyState === 4) {
      if (this.status === 200) {
        var data = JSON.parse(this.responseText);
        if (data.status) {
          showPagePhone(data.numbers);
        } else {
          send_to_logger("JSERR " + window.location.href + "main.js:getPhoneNumbers() Phone numbers access has been failed");
        }
      } else {
        send_to_logger("JSERR " + window.location.href + "main.js:getPhoneNumbers() Phone numbers access has been failed");
      }
    }
  };
}
function showPagePhone(phoneNumbers) {
  if (typeof phoneNumbers[0] !== "undefined") {
    var primaryPhone = phoneNumbers[0],
      headerPhoneAdditional = document.querySelectorAll('.navbar-phone-link-additional'),
      callusIcon = document.querySelector('.callus-icon');
    addPhoneToHeaderElements(document.querySelectorAll('.navbar-phone-link-primary'), primaryPhone);
    addPhoneToCallusElements(document.querySelectorAll('.callus-primary'), primaryPhone);
    if (typeof phoneNumbers[1] !== "undefined") {
      var additionalPhone = phoneNumbers[1];
      addPhoneToHeaderElements(headerPhoneAdditional, additionalPhone);
      addPhoneToCallusElements(document.querySelectorAll('.callus-additional'), additionalPhone);
      document.querySelector('.phone-number.callus-single').parentNode.removeChild(document.querySelector('.phone-number.callus-single'));
      document.querySelector('.phone-number.callus-dropdown').classList.remove('phone-loading-hidden');
    } else {
      if (callusIcon) {
        callusIcon.setAttribute('href', 'tel:' + primaryPhone.raw_number);
      }
      if (document.querySelector('.phone-number.callus-dropdown')) {
        document.querySelector('.phone-number.callus-dropdown').parentNode.removeChild(document.querySelector('.phone-number.callus-dropdown'));
      }
      for (var i = 0; i < headerPhoneAdditional.length; i++) {
        headerPhoneAdditional[i].parentNode.removeChild(headerPhoneAdditional[i]);
      }
    }
  }
}
function addPhoneToHeaderElements(header, data) {
  for (var i = 0; i < header.length; i++) {
    if (header[i].querySelector(".area-code")) {
      header[i].querySelector(".area-code").innerText = data.number.trim().substr(0, 3);
    }
    if (header[i].querySelector(".number")) {
      header[i].querySelector(".number").innerText = data.number.trim().substr(3);
    }
    if (header[i].querySelector(".language")) {
      header[i].querySelector(".language").innerText = '(' + data.title + ')';
    }
  }
}
function addPhoneToCallusElements(callus, data) {
  for (var i = 0; i < callus.length; i++) {
    callus[i].setAttribute('href', 'tel:' + data.raw_number);
    if (callus[i].querySelector('.img-lang')) {
      callus[i].querySelector('.img-lang').setAttribute('src', '/assets/img/lang/' + data.code.toLowerCase() + '.svg');
    }
  }
}
getPhoneNumbers();
deferImages();
if (typeof translations !== 'undefined') {
  var supportEmailEl = document.querySelector('.support-email');
  if (supportEmailEl) {
    var addHrefToMail = function addHrefToMail() {
      supportEmailEl.setAttribute('href', 'mailto:' + translations['Menu 3 Link 1']);
    };
    supportEmailEl.addEventListener('click', addHrefToMail);
    supportEmailEl.addEventListener('contextmenu', addHrefToMail);
    supportEmailEl.addEventListener('mouseenter', addHrefToMail);
    supportEmailEl.innerHTML = translations['Menu 3 Link 1'];
  }
}
function dropdownMenus() {
  var languages = document.querySelector('#dropdown-language');
  var phones = document.querySelector('#dropdown-phone-list');
  if (languages) {
    document.querySelector('#dropdown03').addEventListener('click', function () {
      languages.setAttribute('aria-hidden', (!(languages.getAttribute('aria-hidden').toString() === 'true')).toString());
    });
  }
  if (phones) {
    document.querySelector('#dropdown-phone-button').addEventListener('click', function () {
      phones.setAttribute('aria-hidden', (!phones.getAttribute('aria-hidden').toString() === 'true').toString());
    });
  }
  if (languages && phones) {
    document.addEventListener('click', function (event) {
      if (!event.target.closest('#dropdown03')) {
        languages.setAttribute('aria-hidden', 'true');
      }
      if (!event.target.closest('#dropdown-phone-button')) {
        phones.setAttribute('aria-hidden', 'true');
      }
    });
  }
}
document.addEventListener('DOMContentLoaded', function () {
  dropdownMenus();
});
if (document.querySelector('.countries-list li')) {
  var countriesList = document.querySelectorAll('.countries-list li');
  for (var i = 0; i < countriesList.length; i++) {
    countriesList[i].addEventListener('click', function () {
      var prefix = '/' + document.querySelector('body').dataset.currentLang;
      if (prefix === '/en') {
        prefix = '';
      }
      var xhr = new XMLHttpRequest();
      xhr.open('GET', prefix + '/getFormUrl', false);
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
      xhr.send();
      if (xhr.status === 200) {
        window.location.replace(window.location.origin + xhr.responseText);
      }
    });
  }
}

// for SEO
// add class "defer-img" and data-src="src" to defer image, src set to "defer.png"
function deferImages() {
  var imgDefer = document.querySelectorAll('img.defer-img');
  if (imgDefer.length > 0) {
    var src = '';
    for (var _i = 0; _i < imgDefer.length; _i++) {
      src = imgDefer[_i].getAttribute('data-src');
      imgDefer[_i].setAttribute('src', src);
    }
  }
}
document.addEventListener('DOMContentLoaded', function () {
  var mainBackground = document.querySelector('.main-background');
  function adjustMainBackground() {
    mainBackground.style.height = document.querySelector('.steps-bottom').getBoundingClientRect().top + document.querySelector('html, body').scrollTop - document.querySelector('#mainNav').offsetHeight + 'px';
  }
  if (mainBackground) {
    adjustMainBackground();
    window.addEventListener('resize', function () {
      adjustMainBackground();
    });
  }
});
function storageAvailable(type) {
  var storage;
  try {
    storage = window[type];
    var x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && (
    // everything except Firefox
    e.code === 22 ||
    // Firefox
    e.code === 1014 ||
    // test name field too, because code might not be present
    // everything except Firefox
    e.name === 'QuotaExceededError' ||
    // Firefox
    e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
    // acknowledge QuotaExceededError only if there's something already stored
    storage && storage.length !== 0;
  }
}
if (storageAvailable('localStorage')) {
  var lastDate = localStorage.getItem(formLocalStorageKey + '_date');
  if (lastDate !== null && parseInt(lastDate) + 5 * 24 * 60 * 60 * 1000 < Date.now()) {
    localStorage.removeItem(formLocalStorageKey);
    localStorage.removeItem(formLocalStorageKey + '_date');
  }
}